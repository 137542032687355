import { Box } from '@mui/material'
import { Fragment } from 'react'

import {
  Banner,
  TwoSideList,
  StartMining,
  WhyUs,
  Faq,
  // Partners,
  ReadyToStartMining,
  MainGradients,
} from '@widgets/main-page'

export function MainPage() {
  return (
    <Fragment>
      <MainGradients />

      <Box sx={{ position: 'relative', zIndex: 3 }} component="main">
        <Banner />
        <TwoSideList sx={{ mb: { xs: 10, md: 25.75 }, pt: { md: 23 } }} />
        <StartMining sx={{ mb: { xs: 11.5, md: 25 } }} />
        <WhyUs sx={{ mb: { xs: 10, md: 30 } }} />
        <Faq sx={{ mb: { xs: 11, md: 35 } }} />
        {/*<Partners sx={{ mb: { xs: 15, md: 17.5 } }} />*/}
        <ReadyToStartMining sx={{ mb: { xs: 10, md: 26.25 } }} />
      </Box>
    </Fragment>
  )
}
