import { Box, Divider, Link, Stack, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as MinerexLogo } from './assets/minerex-logo.svg'

interface IProps {
  type: 'header' | 'footer'
}

export function Logo({ type }: IProps) {
  const { t } = useTranslation('entities')
  const theme = useTheme()

  return (
    <Link
      href="/"
      sx={{ textDecoration: 'none', color: theme.palette.common.white }}
    >
      <Stack direction="row" alignItems="center" gap={{ xs: 1, sm: 1.5 }}>
        <Stack direction="row" gap={1} alignItems="center">
          <Box
            sx={{
              '& svg': {
                width: type === 'header' ? 48 : 40,
                height: type === 'header' ? 48 : 40,
                display: 'block',
              },
            }}
          >
            <MinerexLogo />
          </Box>

          <Typography variant={type === 'header' ? 'logoHeader' : 'logoFooter'}>
            Minerex
          </Typography>
        </Stack>

        <Divider
          orientation="vertical"
          flexItem
          sx={{
            backgroundColor: (theme) => theme.palette.common.white,
            height: 32,
            alignSelf: 'center',
          }}
        />

        <Typography mt={0.25} variant="body6">
          {t('logo.subtext')}
        </Typography>
      </Stack>
    </Link>
  )
}
