import { createTheme } from '@mui/material'
import { palette } from './palette'

export const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1248,
      xl: 1536,
    },
  },
  palette: palette,
})
