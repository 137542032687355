import {
  createBrowserRouter,
  RouterProvider as ReactRouterProvider,
} from 'react-router-dom'

import { MainPageRouter } from '@pages/main'
import { RedirectPageRoute } from '@pages/redirect'
import { TermsPageRouter } from '@pages/terms'
import { MainLayout } from './layouts'

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [MainPageRouter, TermsPageRouter, RedirectPageRoute],
  },
])

export function RouterProvider() {
  return <ReactRouterProvider router={router} />
}
