import { styled } from '@mui/material'
import ListMarker from '@widgets/main-page/banner/ui/components/assets/list-marker.svg'

export const StyledListItem = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  listStyleType: 'none',
  paddingLeft: 0,
  '&::before': {
    content: `url(${ListMarker})`,
    display: 'inline-block',
    height: '24px',
    width: '24px',
    marginRight: theme.spacing(1),
    position: 'relative',
    top: -3,
  },

  [theme.breakpoints.down('md')]: {
    '&::before': {
      top: 0,
    },
  },
}))
