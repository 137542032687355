import { Typography, styled } from '@mui/material'

export interface IProps {
  reverse?: boolean
  component: React.ElementType
}
export const StyledTypography = styled(Typography)<IProps>(
  ({ theme, reverse }) => ({
    maxWidth: 470,
    ...(reverse && { marginLeft: 'auto' }),
    color: theme.palette.text.disabled,
    [theme.breakpoints.up('md')]: {
      color: theme.palette.text.primary,
    },
  })
)
