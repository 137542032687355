import { Box, SxProps } from '@mui/material'
import { useEffect, useState } from 'react'
import { ReactComponent as CircleImg } from '../assets/circle-pattern.svg'

interface IProps {
  size?: number
  sx?: SxProps
  reverse?: boolean
}

export function CircleBackground({ reverse, sx, size }: IProps) {
  const [deg, setDeg] = useState(0)

  const handleScroll = () => {
    const scrollProgress =
      window.scrollY / (document.body.clientHeight - window.innerHeight)

    setDeg(scrollProgress * 360)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    // Убираем обработчик при размонтировании компонента
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const calcDegWithFactor = (factor: number) => {
    return Math.ceil(deg * factor) % 360
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        ...(reverse && { left: -88, transform: 'scale(-1, 1)' }),
        ...(!reverse && { right: -88 }),
        '& svg': {
          ...(size && { width: size, height: size }),
        },

        '& .circle1': {
          transform: `rotate(${calcDegWithFactor(1.2)}deg)`,
          transformOrigin: '50% 50%',
        },
        '& .circle2': {
          transform: `rotate(${calcDegWithFactor(1.4)}deg)`,
          transformOrigin: '50% 50%',
        },
        '& .circle3': {
          transform: `rotate(${calcDegWithFactor(1.5)}deg)`,
          transformOrigin: '50% 50%',
        },
        '& .circle4': {
          transform: `rotate(${calcDegWithFactor(1.7)}deg)`,
          transformOrigin: '50% 50%',
        },
        '& .circle5': {
          transform: `rotate(${calcDegWithFactor(1.9)}deg)`,
          transformOrigin: '50% 50%',
        },

        ...sx,
      }}
    >
      <CircleImg />
    </Box>
  )
}
