import { styled } from '@mui/material'

interface IProps {
  isChrome: boolean
}

export const StyledBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isChrome',
})<IProps>(({ isChrome }) => ({
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1,
  ...(isChrome && { filter: 'blur(200px)' }),
}))
