import { Stack, SxProps } from '@mui/material'
import { useState } from 'react'

import { Map } from '@shared/react-lodash'
import { scrollToElementTop } from '@shared/utils'

import { IOnChange, useQuestions } from '../../model'
import { FaqItem } from './faq-item'

interface IProps {
  sx?: SxProps
}

export function FaqList({ sx }: IProps) {
  const questions = useQuestions()
  const [expanded, setExpanded] = useState<number | false>(false)

  const handleChange =
    (panel: number) =>
    ({ isExpanded, ref }: IOnChange) => {
      setExpanded(isExpanded ? panel : false)

      isExpanded && setTimeout(scrollToElementTop, 600, ref)
    }

  return (
    <Stack sx={sx}>
      <Map
        collection={questions}
        iterate={(question, index) => (
          <FaqItem
            key={index}
            item={question}
            expanded={expanded === index}
            onChange={handleChange(index || 0)}
          />
        )}
      />
    </Stack>
  )
}
