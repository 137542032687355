import { Box, Stack, SxProps, Typography } from '@mui/material'

interface IProps {
  sx?: SxProps
  title: string
}

export function UnderlineHeader({ sx, title }: IProps) {
  return (
    <Stack
      alignItems={'center'}
      gap={{ xs: 1.5, md: 3 }}
      mb={{ xs: 5, md: 10 }}
      sx={sx}
    >
      <Typography variant={'h3'}>{title}</Typography>

      <Box
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.primary.main}`,
          width: { xs: 64, md: 80 },
          mx: 'auto',
        }}
      />
    </Stack>
  )
}
