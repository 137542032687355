import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, ScrollRestoration, useSearchParams } from 'react-router-dom'

import { Footer } from '@widgets/footer'
import { Header } from '@widgets/header'

import { LayoutWrapper } from './styled'

export function MainLayout() {
  const {
    i18n: { changeLanguage },
  } = useTranslation()

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const lang = searchParams.get('lang')

    if (lang) {
      changeLanguage(lang)
    }
  }, [])

  return (
    <LayoutWrapper>
      <Header sx={{ position: 'relative', zIndex: 7 }} />

      <Box flex={1} zIndex={6}>
        <Outlet />
      </Box>

      <Footer sx={{ position: 'relative', zIndex: 7, flex: 0 }} />
      <ScrollRestoration />
    </LayoutWrapper>
  )
}
