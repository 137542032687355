import { styled } from '@mui/material'

interface IProps {
  reverse?: boolean
}

export const Divider = styled('div', {
  shouldForwardProp: (prop) => prop !== 'reverse',
})<IProps>(({ theme, reverse = false }) => ({
  width: '100%',
  height: 1,
  background: theme.palette.primary.main,
  position: 'relative',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),

  ...(reverse && {
    left: '60%',
  }),

  ...(!reverse && {
    right: '60%',
  }),

  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}))
