import { Typography, styled } from '@mui/material'

export const StyledTitle = styled(Typography)(({ theme }) => ({
  whiteSpace: 'normal',
  textAlign: 'center',
  marginBottom: theme.spacing(2),

  [theme.breakpoints.up('md')]: {
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
    marginBottom: theme.spacing(5.5),
  },
}))
