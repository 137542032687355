import { RefObject } from 'react'

export const scrollToElementTop = (ref: RefObject<HTMLDivElement>) => {
  if (!ref.current) {
    return
  }

  const blockOffset =
    ref.current.getBoundingClientRect().top + window.pageYOffset

  window.scrollTo({
    top: blockOffset,
    behavior: 'smooth',
  })
}
