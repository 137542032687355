import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Map } from '@shared/react-lodash'

import { StyledListItem } from './styled'

export function MineCoinsList() {
  const { t } = useTranslation('widgets')

  const list = [
    t('main-page.banner.list.1'),
    t('main-page.banner.list.2'),
    t('main-page.banner.list.3'),
  ]

  return (
    <Stack component="ul" gap={1} p={0}>
      <Map
        collection={list}
        iterate={(item, index) => (
          <StyledListItem key={index}>
            <Typography component={'span'} color={'text.disabled'}>
              {item}
            </Typography>
          </StyledListItem>
        )}
      />
    </Stack>
  )
}
