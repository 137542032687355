import { AccordionSummary, styled } from '@mui/material'

export const StyledAccordionDetails = styled(AccordionSummary)(({ theme }) => ({
  marginLeft: theme.spacing(6.75),
  borderLeft: `1px solid ${theme.palette.primary.main}`,
  padding: 0,
  paddingLeft: theme.spacing(2),
  marginBottom: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(5.75),
  },
}))
