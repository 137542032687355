import { styled } from '@mui/material'

export const StyledAnimationWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -45,
  left: '50%',
  transform: 'translateX(-50%)',

  [theme.breakpoints.up('md')]: {
    top: -135,
  },
}))
