import { Typography } from '@mui/material'
import { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'

interface IQuestion {
  question: string
  answer: ReactElement
}

export const useQuestions = () => {
  const { t } = useTranslation('widgets')

  const getCorrectTranslation = (key: string) => {
    return (
      <Trans
        i18nKey={key as never}
        ns={'widgets'}
        components={{
          p: <Typography sx={{ mb: 2 }} />,
          ol: <ol style={{ margin: 0, marginBottom: 16 }} />,
          ul: <ul style={{ margin: 0, marginBottom: 16 }} />,
          li: <li style={{ margin: 4 }} />,
        }}
      />
    )
  }

  const questions: IQuestion[] = [
    {
      question: t('main-page.faq.list.0.question'),
      answer: getCorrectTranslation('main-page.faq.list.0.answer'),
    },
    {
      question: t('main-page.faq.list.1.question'),
      answer: getCorrectTranslation('main-page.faq.list.1.answer'),
    },
    {
      question: t('main-page.faq.list.2.question'),
      answer: getCorrectTranslation('main-page.faq.list.2.answer'),
    },
    {
      question: t('main-page.faq.list.3.question'),
      answer: getCorrectTranslation('main-page.faq.list.3.answer'),
    },
  ]

  return questions
}
