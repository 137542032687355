import { Box, styled } from '@mui/material'

export const ItemBox = styled(Box)(({ theme }) => ({
  height: '100%',
  minHeight: 154,
  paddingTop: 16,
  paddingBottom: 16,
  paddingLeft: 16,
  paddingRight: 24,
  borderRadius: 16,
  border: `2px solid ${theme.palette.cBlue.main}`,
  background: theme.palette.cBlue.dark,
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  '&:hover': {
    paddingTop: 11,
    paddingBottom: 11,
    borderBottomWidth: 10,
  },
  transition: theme.transitions.create(['padding', 'border'], {
    duration: theme.transitions.duration.shortest,
  }),

  [theme.breakpoints.up('md')]: {
    minHeight: 282,
    paddingTop: 38,
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    gap: 6,

    '&:hover': {
      paddingBottom: 19,
      paddingTop: 33,
      borderBottomWidth: 10,
    },
  },
}))
