import { Container, Stack, SxProps, Theme } from '@mui/material'
import { Logo } from '@entities/logo'

interface IProps {
  sx?: SxProps<Theme>
}

export function Header({ sx }: IProps) {
  return (
    <Container component={'header'} maxWidth={'lg'} sx={sx}>
      <Stack alignItems={'flex-start'} py={{ xs: 2.5, md: 2 }}>
        <Logo type={'header'} />
      </Stack>
    </Container>
  )
}
