import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Connect } from './assets/connect.svg'
import { ReactComponent as Data } from './assets/data.svg'
import { ReactComponent as Money } from './assets/money.svg'
import { ReactComponent as Support } from './assets/support.svg'

interface IWhyUsItem {
  title: string
  description: string
  icon: ReactNode
}

export const useWhyUsItems = () => {
  const { t } = useTranslation('widgets')

  const items: IWhyUsItem[] = [
    {
      title: t('main-page.why-us.list.0.title'),
      description: t('main-page.why-us.list.0.description'),
      icon: <Support />,
    },
    {
      title: t('main-page.why-us.list.1.title'),
      description: t('main-page.why-us.list.1.description'),
      icon: <Connect />,
    },
    {
      title: t('main-page.why-us.list.2.title'),
      description: t('main-page.why-us.list.2.description'),
      icon: <Money />,
    },
    {
      title: t('main-page.why-us.list.3.title'),
      description: t('main-page.why-us.list.3.description'),
      icon: <Data />,
    },
  ]

  return items
}
