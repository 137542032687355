import { Container, styled } from '@mui/material'
import * as React from 'react'

interface IProps {
  component?: React.ElementType
}

export const StyledContainer = styled(Container)<IProps>(() => ({
  minHeight: 'inherit',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  position: 'relative',
}))
