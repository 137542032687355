import {
  Container,
  Grid,
  Stack,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ShowQrCode } from '@features/show-qr-code'
import { StartMiningButton } from '@features/start-mining-button'
import { MobileAppLinks } from '@entities/mobile-app-links'
import { QrCodeGenerator } from '@entities/qr-code-generator'

interface IProps {
  sx?: SxProps
}

export function ReadyToStartMining({ sx }: IProps) {
  const { t } = useTranslation('widgets')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Container component="section" maxWidth="lg" sx={sx}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            {t('main-page.ready-to-start-mining.title')}
          </Typography>

          <Typography
            variant="body3"
            sx={{ mb: { xs: 4, md: 4.5 } }}
            component={'p'}
          >
            {t('main-page.ready-to-start-mining.description')}
          </Typography>

          {mdAndUp && <StartMiningButton sx={{ width: 282 }} />}
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack alignItems={'center'}>
            {!mdAndUp && <StartMiningButton fullWidth sx={{ mb: 3 }} />}

            {mdAndUp && (
              <QrCodeGenerator sx={{ width: 153, height: 153, mb: 4.25 }} />
            )}

            <MobileAppLinks
              sx={{ mb: { xs: 3, md: 0 } }}
              buttonsSx={{ height: { xs: 49, md: 'initial' }, width: '100%' }}
            />

            {!mdAndUp && <ShowQrCode />}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  )
}
