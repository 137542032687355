import {
  CssBaseline,
  LinkProps,
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material'
import React, { ReactNode } from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'
import { defaultTheme } from './default-theme.ts'
import { palette } from './palette.ts'
import { typography } from './typography.ts'

interface IProps {
  children: ReactNode
}

// eslint-disable-next-line react/display-name
const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & {
    href: RouterLinkProps['to']
  }
>((props, ref) => {
  const { href, ...other } = props

  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />
})

const theme = createTheme({
  breakpoints: defaultTheme.breakpoints,
  palette: palette,
  typography: typography,
  components: {
    MuiButton: {
      variants: [
        {
          props: { rounded: true },
          style: {
            borderRadius: '100px',
            color: defaultTheme.palette.text.secondary,
          },
        },
        {
          props: { color: 'primary' },
          style: {
            '&:hover': {
              backgroundColor: defaultTheme.palette.cYellow.hovered,
            },
            '&:focus-within, &:focus-visible': {
              backgroundColor: defaultTheme.palette.cYellow.focused,
            },
          },
        },
        {
          props: { color: 'primary', disabled: true },
          style: {
            backgroundColor: defaultTheme.palette.cYellow.disabled,
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: 8,
          '.MuiButton-iconSizeSmall svg': {
            fontSize: 32,
          },
          '.MuiButton-iconSizeMedium svg': {
            fontSize: 36,
          },
        },
        sizeSmall: {
          padding: '16px',
          fontWeight: 600,
          fontSize: 28,
          lineHeight: '34px',
        },
        sizeMedium: {
          padding: '20px',
          [defaultTheme.breakpoints.down('md')]: {
            padding: '16px',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: { LinkComponent: LinkBehavior },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '.MuiContainer-root': {
            [defaultTheme.breakpoints.up('xs')]: {
              paddingLeft: '16px',
              paddingRight: '16px',
            },
          },
        },
      },
    },
  },
})

export function ThemeProvider({ children }: IProps) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  )
}
