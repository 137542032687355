import { Container, Stack, SxProps, Typography } from '@mui/material'

import { Picture, TImage } from '@shared/picture'
import { Map } from '@shared/react-lodash'
import { TwoSideBlock } from '@shared/two-side-block'

import { useContent } from '../model'
import { StyledTypography } from './styled'

interface IProps {
  sx?: SxProps
}

export function TwoSideList({ sx }: IProps) {
  const content = useContent()

  return (
    <Container component="section" maxWidth="lg" sx={sx}>
      <Stack gap={{ xs: 10.5, md: 36 }}>
        <Map
          collection={content}
          iterate={(item, index) => (
            <TwoSideBlock
              key={index}
              title={<Typography variant="h2">{item.title}</Typography>}
              content={
                <StyledTypography
                  variant="body3"
                  component="p"
                  reverse={item.reverse}
                >
                  {item.description}
                </StyledTypography>
              }
              sideContent={
                <Picture
                  png={item.imagePng as TImage<'png'>}
                  webp={item.imageWebp as TImage<'webp'>}
                  sx={{ maxWidth: 570, mx: 'auto' }}
                />
              }
              reverse={item.reverse}
            />
          )}
        />
      </Stack>
    </Container>
  )
}
