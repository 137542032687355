import { Box, Stack, SxProps, Theme } from '@mui/material'

import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx'
import { APP_STORE, GOOGLE_PLAY } from '@shared/constants'
import { ReactComponent as AppStoreBadge } from './assets/app-store-badge.svg'
import { ReactComponent as GooglePlayBadge } from './assets/google-play-badge.svg'

interface IProps {
  sx?: SxProps<Theme>
  direction?: 'row' | 'column'
  buttonsSx?: SystemStyleObject<Theme>
}

export function MobileAppLinks({ direction = 'row', sx, buttonsSx }: IProps) {
  return (
    <Stack direction={direction} columnGap={1.5} rowGap={2} sx={sx}>
      <Box
        component="a"
        href={GOOGLE_PLAY}
        target={'_blank'}
        sx={{
          textDecoration: 'none',
          '& svg': { display: 'block', ...buttonsSx },
        }}
      >
        <GooglePlayBadge />
      </Box>

      <Box
        component="a"
        href={APP_STORE}
        target={'_blank'}
        sx={{
          textDecoration: 'none',
          '& svg': { display: 'block', ...buttonsSx },
        }}
      >
        <AppStoreBadge />
      </Box>
    </Stack>
  )
}
