import { Box, styled } from '@mui/material'

export const IconWrapper = styled(Box)(({ theme }) => ({
  width: 44,
  height: 44,
  '& svg': {
    width: '100%',
    height: '100%',
  },
  [theme.breakpoints.up('md')]: {
    width: 76,
    height: 76,
  },
}))
