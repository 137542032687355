import { styled } from '@mui/material'

export const Wrapper = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  width: 200,
  height: 200,
  borderRadius: '12px',
  padding: theme.spacing(1),
  marginBottom: theme.spacing(6),
}))
