import { TypographyOptions } from '@mui/material/styles/createTypography'
import * as React from 'react'

import { defaultTheme } from './default-theme'

interface ExtendedTypographyOptions extends TypographyOptions {
  body3: React.CSSProperties
  body4: React.CSSProperties
  body5: React.CSSProperties
  body6: React.CSSProperties
  link: React.CSSProperties
  logoHeader: React.CSSProperties
  logoFooter: React.CSSProperties
}

export const typography: ExtendedTypographyOptions = {
  fontFamily: ['Ubuntu', 'sans-serif'].join(','),
  h1: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontSize: 64,
    fontWeight: 500,
    [defaultTheme.breakpoints.down('md')]: {
      fontSize: 36,
      fontWeight: 400,
    },
  },
  h2: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontSize: 48,
    fontWeight: 400,
    [defaultTheme.breakpoints.down('md')]: {
      fontSize: 28,
    },
  },
  h3: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontSize: 48,
    fontWeight: 400,
    [defaultTheme.breakpoints.down('md')]: {
      fontSize: 28,
      fontWeight: 700,
    },
  },
  subtitle1: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontSize: 28,
    fontWeight: 400,
    [defaultTheme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  },
  subtitle2: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontSize: 24,
    fontWeight: 400,
    textTransform: 'uppercase',
    [defaultTheme.breakpoints.down('md')]: {
      fontSize: 20,
      lineHeight: 1.5,
    },
  },
  body1: {
    fontFamily: ['IBM Plex Sans', 'sans-serif'].join(','),
    fontSize: 24,
    fontWeight: 400,
    lineHeight: 1.5,
    [defaultTheme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  body2: {
    fontFamily: ['IBM Plex Sans', 'sans-serif'].join(','),
    fontSize: 24,
    fontWeight: 400,
    lineHeight: 1.5,
    [defaultTheme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  body3: {
    fontFamily: ['IBM Plex Sans', 'sans-serif'].join(','),
    fontSize: 20,
    fontWeight: 400,
    lineHeight: 1.5,
    [defaultTheme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  body4: {
    fontFamily: ['IBM Plex Sans', 'sans-serif'].join(','),
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  body5: {
    fontFamily: ['IBM Plex Sans', 'sans-serif'].join(','),
    fontSize: 16,
    fontWeight: 300,
    lineHeight: 1.5,
  },
  body6: {
    fontFamily: ['IBM Plex Sans', 'sans-serif'].join(','),
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    [defaultTheme.breakpoints.down('md')]: {
      fontSize: 15,
    },
  },
  button: {
    fontFamily: ['IBM Plex Sans', 'sans-serif'].join(','),
    fontSize: 18,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '0.72px',
  },
  link: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontSize: 19,
    fontWeight: 400,
    lineHeight: 1.5,
    textDecoration: 'none',
    color: 'inherit',
    [defaultTheme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  logoHeader: {
    fontFamily: ['Zen Dots', 'sans-serif'].join(','),
    fontSize: 38,
    letterSpacing: '0.6px',
    [defaultTheme.breakpoints.down('md')]: {
      fontSize: 26,
      letterSpacing: '0.52px',
    },
  },
  logoFooter: {
    fontFamily: ['Zen Dots', 'sans-serif'].join(','),
    fontSize: 28,
    letterSpacing: '0.56px',
    [defaultTheme.breakpoints.down('md')]: {
      fontSize: 26,
      letterSpacing: '0.52px',
    },
  },
}
