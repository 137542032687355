import { Box, styled } from '@mui/material'

export const LayoutWrapper = styled(Box)(() => ({
  overflow: 'hidden',
  position: 'relative',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}))
