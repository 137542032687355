import { Box } from '@mui/material'

import { styled } from '@mui/material'

export const BlurFilterBox = styled(Box)(() => ({
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backdropFilter: 'blur(200px)',
  background: 'rgba(0, 1, 3, 0.30)',
  zIndex: 2,
}))
