import { Stack, styled } from '@mui/material'

export const LinksWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  rowGap: theme.spacing(3.5),
  columnGap: theme.spacing(6),
  marginBottom: theme.spacing(0.5),

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    marginBottom: 0,
  },
}))
