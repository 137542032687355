import { Player } from '@lottiefiles/react-lottie-player'
import {
  Container,
  Grid,
  SxProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { UnderlineHeader } from '@shared/underline-header'

import animationJSON from './assets/animation.json'
import { ReactComponent as BgIllustration } from './assets/bg.svg'

import { FaqList } from './components'
import { StyledIllustrationBox } from './styled'

interface IProps {
  sx?: SxProps
}

export function Faq({ sx }: IProps) {
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation('widgets')

  return (
    <Container
      maxWidth={'lg'}
      component={'section'}
      sx={{ position: 'relative', ...sx }}
    >
      <StyledIllustrationBox>
        <BgIllustration />
      </StyledIllustrationBox>

      <UnderlineHeader
        title={
          mdAndUp ? t('main-page.faq.title') : t('main-page.faq.title-mobile')
        }
        sx={{ mb: { xs: 5, md: 10 } }}
      />

      <Grid container columnSpacing={3}>
        <Grid item xs={12} md={6} display="flex" alignItems="center">
          <FaqList />
        </Grid>

        <Grid item xs={12} md={6} display="flex" justifyContent="center">
          <Player
            style={{
              width: mdAndUp ? 500 : 375,
              height: mdAndUp ? 500 : 375,
            }}
            src={animationJSON}
            autoplay
            loop={true}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
