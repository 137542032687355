import { Box, styled } from '@mui/material'

export const BgIllustrationWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  maxHeight: 'inherit',
  top: 0,
  left: 0,
  overflow: 'hidden',

  '& svg': {
    width: 1082,
    height: 996,
    position: 'absolute',
    top: 0,
    left: 'calc(50% + 365px)',
    transform: 'translateX(-50%)',
  },

  [theme.breakpoints.down('md')]: {
    top: 50,
    '& svg': {
      width: 728,
      height: 662,
      left: 'calc(50% + 40px)',
    },
  },
}))
