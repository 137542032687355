import { useTranslation } from 'react-i18next'

import FourthPng from './assets/get-your-coins.png'
import FourthWebp from './assets/get-your-coins.webp'
import FirstPng from './assets/install-minerex.png'
import FirstWebp from './assets/install-minerex.webp'
import ThirdPng from './assets/start-mining.png'
import ThirdWebp from './assets/start-mining.webp'
import SecondPng from './assets/top-up-balance.png'
import SecondWebp from './assets/top-up-balance.webp'

interface IContent {
  title: string
  description: string
  imagePng: string
  imageWebp: string
  reverse?: boolean
}

export const useContent = () => {
  const { t } = useTranslation('widgets')

  const content: IContent[] = [
    {
      title: t('main-page.two-side-list.0.title'),
      description: t('main-page.two-side-list.0.description'),
      imagePng: FirstPng,
      imageWebp: FirstWebp,
    },
    {
      title: t('main-page.two-side-list.1.title'),
      description: t('main-page.two-side-list.1.description'),
      imagePng: SecondPng,
      imageWebp: SecondWebp,
      reverse: true,
    },
    {
      title: t('main-page.two-side-list.2.title'),
      description: t('main-page.two-side-list.2.description'),
      imagePng: ThirdPng,
      imageWebp: ThirdWebp,
    },
    {
      title: t('main-page.two-side-list.3.title'),
      description: t('main-page.two-side-list.3.description'),
      imagePng: FourthPng,
      imageWebp: FourthWebp,
      reverse: true,
    },
  ]

  return content
}
