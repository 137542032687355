import AddRoundedIcon from '@mui/icons-material/AddRounded'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'
import {
  Accordion,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material'
import { ReactElement, useRef } from 'react'

import { IOnChange } from '../../model'
import { iconSummarySx, StyledAccordionDetails } from './styled'

interface IProps {
  item: {
    question: string
    answer: ReactElement
  }
  expanded: boolean
  onChange: (props: IOnChange) => void
}

export function FaqItem({ item, expanded, onChange }: IProps) {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, isExpanded) => onChange({ isExpanded, ref })}
      ref={ref}
      sx={{ background: 'transparent' }}
    >
      <AccordionSummary sx={{ p: 0 }}>
        <Stack direction={'row'} alignItems={'center'} gap={{ xs: 1, md: 2 }}>
          {expanded ? (
            <RemoveRoundedIcon sx={iconSummarySx} />
          ) : (
            <AddRoundedIcon sx={iconSummarySx} />
          )}

          <Typography variant={'subtitle2'}>{item.question}</Typography>
        </Stack>
      </AccordionSummary>

      <StyledAccordionDetails>
        <Box>{item.answer}</Box>
      </StyledAccordionDetails>
    </Accordion>
  )
}
