import { Box, Stack, SxProps, useMediaQuery, useTheme } from '@mui/material'
import { Fragment, ReactNode } from 'react'
import { CircleBackground } from './components'
import { Divider, Wrapper } from './styled'

interface IProps {
  sx?: SxProps
  reverse?: boolean
  title?: ReactNode
  content?: ReactNode
  sideContent?: ReactNode
}

export function TwoSideBlock({
  sideContent,
  content,
  reverse,
  title,
  sx,
}: IProps) {
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Wrapper sx={sx} reverse={reverse || !mdAndUp}>
      <Stack className={'content'} flex={1} position={'relative'}>
        <Box sx={{ ...(reverse && { textAlign: 'right' }) }} mt={4}>
          {title}
        </Box>

        <Divider reverse={reverse} />

        {!mdAndUp && (
          <Fragment>
            <CircleBackground
              reverse={reverse}
              sx={{
                top: 50,
                ...(reverse && { left: -20 }),
                ...(!reverse && { right: -20 }),
              }}
              size={152}
            />

            <Box sx={{ position: 'relative', mb: 3 }}>{sideContent}</Box>
          </Fragment>
        )}

        <Box
          sx={{
            ...(reverse && { textAlign: 'right' }),
            whiteSpace: 'pre-line',
          }}
        >
          {content}
        </Box>
      </Stack>

      <Box className={'side-content'} sx={{ position: 'relative' }} flex={1}>
        {mdAndUp && (
          <Fragment>
            <CircleBackground reverse={reverse} />
            <Box sx={{ position: 'relative' }}>{sideContent}</Box>
          </Fragment>
        )}
      </Box>
    </Wrapper>
  )
}
