import {
  Container,
  useTheme,
  Stack,
  Typography,
  useMediaQuery,
  SxProps,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Logo } from '@entities/logo'

import { MobileAppLinks } from '@entities/mobile-app-links'
import { routePaths } from '@shared/constants'

import { ReactComponent as BgMobile } from './assets/bg-mobile.svg'
import { ReactComponent as Bg } from './assets/bg.svg'

import {
  BgImgWrapper,
  LinksWrapper,
  LogoWrapper,
  Wrapper,
  StyledLink,
} from './styled'

interface IProps {
  sx?: SxProps
}

export function Footer({ sx }: IProps) {
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const smAndDown = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation('widgets')

  return (
    <Wrapper sx={sx} component={'footer'}>
      <Container maxWidth="lg">
        <LogoWrapper>
          <Logo type={'footer'} />
          {mdAndUp && <MobileAppLinks />}
        </LogoWrapper>

        <Stack
          direction={{ xs: 'column-reverse', md: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          position="relative"
          zIndex={1}
          rowGap={3}
        >
          <Typography variant="body5">
            Cophyright © 2024 MINEREX. All rights reserved
          </Typography>

          {!mdAndUp && (
            <MobileAppLinks direction={smAndDown ? 'column' : 'row'} />
          )}

          <LinksWrapper>
            <StyledLink href={routePaths.terms}>
              <Typography variant="link">
                {t('footer.Terms and Conditions')}
              </Typography>
            </StyledLink>

            <StyledLink href={routePaths.terms}>
              <Typography variant="link">{t('footer.Privacy')}</Typography>
            </StyledLink>

            <StyledLink href={routePaths.terms}>
              <Typography variant="link">{t('footer.Security')}</Typography>
            </StyledLink>
          </LinksWrapper>
        </Stack>

        <BgImgWrapper>{mdAndUp ? <Bg /> : <BgMobile />}</BgImgWrapper>
      </Container>
    </Wrapper>
  )
}
