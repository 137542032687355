import { Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { IconWrapper, ItemBox } from './styled'

interface IProps {
  icon: ReactNode
  title: string
  description: string
}

export function WhyUsItem({ icon, title, description }: IProps) {
  return (
    <ItemBox>
      <Stack direction={{ xs: 'row', md: 'column' }} gap={{ xs: 1, md: 2.5 }}>
        <IconWrapper>{icon}</IconWrapper>

        <Typography variant={'subtitle1'} color={'cBlue.main'}>
          {title}
        </Typography>
      </Stack>

      <Typography variant={'body4'}>{description}</Typography>
    </ItemBox>
  )
}
