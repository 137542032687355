import { styled } from '@mui/material'

interface IProps {
  reverse?: boolean
}

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'reverse',
})<IProps>(({ theme, reverse = false }) => ({
  display: 'flex',
  flexDirection: reverse ? 'column' : 'column-reverse',
  gap: 16,
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    flexDirection: reverse ? 'row' : 'row-reverse',
    columnGap: 40,
  },
  [theme.breakpoints.up('lg')]: {
    columnGap: 100,
  },
  [theme.breakpoints.up('xl')]: {
    columnGap: 160,
  },
}))
