import { SxProps, useMediaQuery, useTheme } from '@mui/material'
import { useMemo } from 'react'

import { getCurrentBrowser } from '@shared/utils'

import { ReactComponent as MainBg } from './assets/main-bg.svg'
import { ReactComponent as MobileMainBg } from './assets/mobile-main-bg.svg'

import { BlurFilterBox, StyledBox } from './styled'

interface IProps {
  sx?: SxProps
}

export function MainGradients({ sx }: IProps) {
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  const isChrome = useMemo(() => getCurrentBrowser() === 'Chrome', [])

  return (
    <>
      {!isChrome && <BlurFilterBox />}

      <StyledBox isChrome={isChrome} sx={sx}>
        {mdAndUp ? <MainBg /> : <MobileMainBg />}
      </StyledBox>
    </>
  )
}
