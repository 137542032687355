import { PaletteOptions } from '@mui/material/styles/createPalette'

export const palette: PaletteOptions = {
  primary: {
    main: '#FBAD03',
  },
  background: {
    default: '#000103',
    paper: '#000103',
  },
  text: {
    primary: '#ffffff',
    disabled: '#BDC1CB',
    secondary: '#000107',
  },
  common: {
    white: '#ffffff',
    black: '#000103',
  },
  cGrey: {
    main: '#BDC0CB',
    blue: '#00548B',
  },
  cBlue: {
    main: '#5DB1F1',
    light: '#1789F8',
    dark: '#151E40',
    deep: '#13152A',
    surface: '#10112C',
    gradient: '#3241BF',
  },
  cViolet: {
    main: '#880DBE',
    gradient: '#400A58',
  },
  cYellow: {
    primary: '#FBAD03',
    hovered: '#AD7701',
    disabled: '#382B1B',
    focused: '#FBAD03',
  },
}
