import { Box, Container, Link, Typography } from '@mui/material'
import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { MainGradients } from '@widgets/main-page'

export function TermsPage() {
  const { t } = useTranslation('pages')

  return (
    <Fragment>
      <MainGradients />

      <Container
        maxWidth={'lg'}
        component="main"
        sx={{
          position: 'relative',
          zIndex: 3,
          pt: { xs: 5, md: 12 },
          pb: 18.75,
        }}
      >
        <Typography variant={'h2'} mb={8} textAlign={'center'}>
          {t('terms.title')}
        </Typography>

        {/* <Typography
          variant={'logoFooter'}
          color={'cBlue.main'}
          textAlign={'center'}
          component={'p'}
          mb={8}
        >
          {t('terms.link')}
        </Typography> */}

        <Box
          px={{ xs: 1.5, md: 0 }}
          py={{ xs: 5, md: 0 }}
          borderRadius={'12px'}
          bgcolor={{ xs: 'cBlue.surface', md: 'transparent' }}
        >
          <Trans
            ns={'pages'}
            i18nKey={'terms.text'}
            components={{
              p: (
                <Typography
                  variant={'body3'}
                  component={'p'}
                  mb={1.5}
                  sx={{ whiteSpace: 'pre-wrap' }}
                />
              ),
              em: <em />,
              strong: <strong />,
              ul: <Box component={'ul'} my={0} />,
              li: <Box component={'li'} />,
              a: <Link />,
            }}
          />
        </Box>
      </Container>
    </Fragment>
  )
}
