import { Player } from '@lottiefiles/react-lottie-player'
import {
  Box,
  Grid,
  Stack,
  SxProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ShowQrCode } from '@features/show-qr-code'
import { StartMiningButton } from '@features/start-mining-button'
import { MobileAppLinks } from '@entities/mobile-app-links'
import { QrCodeGenerator } from '@entities/qr-code-generator'
import animationJSON from './assets/animation.json'
import { ReactComponent as BgIllustration } from './assets/bg-illustration.svg'
import { MineCoinsList } from './components'
import {
  BgIllustrationWrapper,
  StyledAnimationWrapper,
  StyledContainer,
  StyledTitle,
} from './styled'

interface IProps {
  sx?: SxProps
}

export function Banner({ sx }: IProps) {
  const { t } = useTranslation('widgets')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box
      component={'section'}
      sx={{
        minHeight: { xs: 700, md: 736 },
        maxHeight: { xs: 'auto', md: 736 },
        position: 'relative',
      }}
    >
      <BgIllustrationWrapper>
        <BgIllustration />
      </BgIllustrationWrapper>

      <StyledContainer maxWidth={'lg'} sx={sx}>
        <Grid container sx={{ width: '100%' }}>
          <Grid item xs={12} md={6}>
            <Stack
              direction="column"
              alignItems={{ xs: 'center', md: 'flex-start' }}
              position={'relative'}
              zIndex={1}
            >
              <StyledTitle variant="h1">
                {t('main-page.banner.title')}
              </StyledTitle>

              <MineCoinsList />
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack
              width={{ xs: '100%', md: 284 }}
              alignItems="center"
              position={'relative'}
              mx={'auto'}
            >
              <StyledAnimationWrapper>
                <Player
                  style={{
                    width: mdAndUp ? 500 : 375,
                    height: mdAndUp ? 500 : 375,
                  }}
                  src={animationJSON}
                  autoplay
                  loop={true}
                />
              </StyledAnimationWrapper>

              <QrCodeGenerator
                sx={{ position: 'relative' }}
                transparent={!mdAndUp}
              />

              <StartMiningButton sx={{ mb: 3 }} fullWidth />

              <MobileAppLinks
                sx={{ mb: { xs: 3, md: 0 }, position: 'relative' }}
                buttonsSx={{ height: 49, width: '100%' }}
              />

              {!mdAndUp && <ShowQrCode />}
            </Stack>
          </Grid>
        </Grid>
      </StyledContainer>
    </Box>
  )
}
