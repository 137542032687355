import { Container, SxProps, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Map } from '@shared/react-lodash'
import { UnderlineHeader } from '@shared/underline-header'
import { useWhyUsItems } from '../model'
import { WhyUsItem } from './components'

interface IProps {
  sx?: SxProps
}

export function WhyUs({ sx }: IProps) {
  const { t } = useTranslation('widgets')
  const items = useWhyUsItems()

  return (
    <Container component={'section'} maxWidth={'lg'} sx={sx}>
      <UnderlineHeader title={t('main-page.why-us.title')} />

      <Grid container spacing={3}>
        <Map
          collection={items}
          iterate={(item, index) => (
            <Grid item xs={12} sm={6} lg={3} key={index}>
              <WhyUsItem {...item} />
            </Grid>
          )}
        />
      </Grid>
    </Container>
  )
}
