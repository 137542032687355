import { Box, styled } from '@mui/material'

export const BgImgWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  '& svg': {
    width: '100vw',
    height: '100%',
  },

  [theme.breakpoints.up('md')]: {
    left: '37%',
    '& svg': {
      width: 'auto',
      height: 'auto',
    },
  },
}))
