import { Stack, styled } from '@mui/material'

export const LogoWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
  position: 'relative',
  zIndex: 1,

  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4.5),
  },
}))
