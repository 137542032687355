import { Box, Collapse, Stack, SxProps, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { QrCodeGenerator } from '@entities/qr-code-generator'

interface IProps {
  sx?: SxProps
}

export function ShowQrCode({ sx }: IProps) {
  const { t } = useTranslation('features')
  const [showQrCode, setShowQrCode] = useState(false)

  return (
    <Stack sx={sx}>
      <Typography
        component={'button'}
        variant={'body2'}
        color={'text.disabled'}
        sx={{
          background: 'transparent',
          border: 'none',
          mb: 3,
          cursor: 'pointer',
        }}
        onClick={() => setShowQrCode(!showQrCode)}
      >
        {showQrCode ? t('show-qr-code.hide') : t('show-qr-code.show')}
      </Typography>

      <Collapse in={showQrCode}>
        <Box component={'div'}>
          <QrCodeGenerator />
        </Box>
      </Collapse>
    </Stack>
  )
}
