import {
  Container,
  Stack,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { ShowQrCode } from '@features/show-qr-code'
import { StartMiningButton } from '@features/start-mining-button'
import { MobileAppLinks } from '@entities/mobile-app-links'
import { QrCodeGenerator } from '@entities/qr-code-generator'

interface IProps {
  sx?: SxProps
}

export function StartMining({ sx }: IProps) {
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation('widgets')

  return (
    <Container component={'section'} maxWidth={'lg'} sx={sx}>
      <Stack alignItems={'center'}>
        {mdAndUp && (
          <Typography variant={'h2'} textAlign={'center'} mb={4.5}>
            {t('main-page.start-mining.title')}
          </Typography>
        )}

        <Stack width={!mdAndUp ? '100%' : 'auto'}>
          {mdAndUp && (
            <Fragment>
              <QrCodeGenerator sx={{ mb: 3, mx: 'auto' }} />

              <Typography
                variant={'body4'}
                color={'text.disabled'}
                textAlign={'center'}
                mb={6}
              >
                {t('main-page.start-mining.description')}
              </Typography>
            </Fragment>
          )}

          <Stack
            alignItems={'center'}
            gap={3}
            width={!mdAndUp ? '100%' : 'auto'}
          >
            <StartMiningButton fullWidth />
            <MobileAppLinks buttonsSx={{ height: 49, width: '100%' }} />
            {!mdAndUp && <ShowQrCode />}
          </Stack>
        </Stack>
      </Stack>
    </Container>
  )
}
