import { SxProps } from '@mui/material'
import QRCode from 'react-qr-code'
import { routePaths } from '@shared/constants'
import { Wrapper } from './styled'

interface IProps {
  transparent?: boolean
  sx?: SxProps
}

export function QrCodeGenerator({ transparent, sx }: IProps) {
  const composeSx: SxProps = transparent
    ? { background: 'transparent', ...sx }
    : { ...sx }

  const link = window.location.origin + routePaths.redirect

  return (
    <Wrapper sx={composeSx}>
      {!transparent && (
        <QRCode value={link} style={{ width: '100%', height: '100%' }} />
      )}
    </Wrapper>
  )
}
