import { Button, SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { APP_STORE, GOOGLE_PLAY } from '@shared/constants'
import { getCurrentBrowser } from '@shared/utils'

interface IProps {
  sx?: SxProps
  fullWidth?: boolean
}

export function StartMiningButton({ sx, fullWidth }: IProps) {
  const { t } = useTranslation('features')

  const isIOS = getCurrentBrowser() === 'Safari'

  return (
    <Button
      variant={'contained'}
      color={'primary'}
      fullWidth={fullWidth}
      sx={{ maxWidth: 344, display: { md: 'none' }, ...sx }}
      href={isIOS ? APP_STORE : GOOGLE_PLAY}
      target={'_blank'}
    >
      {t('start-mining-button.title')}
    </Button>
  )
}
