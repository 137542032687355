import { Container, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Typewriter from 'typewriter-effect'

import { APP_STORE, GOOGLE_PLAY } from '@shared/constants'
import { getCurrentBrowser } from '@shared/utils'
import { ReactComponent as BgIllustration } from './assets/bg-illustration.svg'
import { BgIlWrapper, Wrapper } from './styled'

export function RedirectPage() {
  const { t } = useTranslation('pages')

  const goToAppStore = () => {
    window.location.href =
      getCurrentBrowser() === 'Safari' ? APP_STORE : GOOGLE_PLAY
  }

  return (
    <Wrapper
      minHeight={'50vh'}
      alignItems={'center'}
      justifyContent={'center'}
      position={'relative'}
    >
      <BgIlWrapper>
        <BgIllustration />
      </BgIlWrapper>

      <Container maxWidth={'lg'} sx={{ position: 'relative' }}>
        <Typography
          variant={'h1'}
          textAlign={'center'}
          sx={{ whiteSpace: 'pre-line' }}
        >
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .changeDelay(50)
                .typeString(t('redirect.text'))
                .callFunction(goToAppStore)
                .start()
            }}
          />
        </Typography>
      </Container>
    </Wrapper>
  )
}
